import React from 'react';
import {
  Link
} from "react-router-dom";

const ApplicationList = (props) => {
  var applicationsRend = [];

  const editApp = (app, event) => {
    props.editApplication(app);
  };

  props.applications.forEach(
    function(app) {
      const createdDt = new Date(app.createdTs);
      var loggedInStr = "";

      if (app.userLastLoggedIn && app.userLastLoggedIn != '') {
        const loggedInDt = new Date(app.userLastLoggedIn);
        loggedInStr = loggedInDt.toString("MMM dd YYYY");
      }

      const createdDtStr = createdDt.toString("MMM dd YYYY"); // "Dec 20"
      //const createdDtStr = createdDt.toLocaleString(); // "Dec 20"

      //<Link to={`/admins/applications/edit/${app.appName}`}>

      applicationsRend.push(<tr>
        <td>{app.appName}</td>
        <td>{app.email}</td>
        <td>{createdDtStr}</td>
        <td>{loggedInStr}</td>
        <td>
            <span onClick={(e) => editApp(app, e)}>
            <i className="fa fa-pencil"  />
            </span>
        </td>
      </tr>
      );
    }
  );

  return (
  <div className="dashboard-main-content">
    <div className="medium-6 columns">
      <h1 className="content-heading">Your Applications</h1>
    </div>
    <div className="medium-6 columns text-right">
      <Link to="/admins/applications/new" className="default-btn">
        <i className="fa fa-plus light-color"/>
        <span className="p-l5">New Application</span>
      </Link>
    </div>
    <div className="row full-row m-t30">
      <div className="medium-12 columns">
        <div className="whitebox">
          <div className="filterBar">
            <div className="row full-row m-t30">
              <div className="medium-12 columns">
                <input type="text" placeholder="Search here..." />
              </div>
            </div>
          </div>
          <div className="responsive-table">
            <table cellPadding="0" celspacing="0">
          <thead>
          <tr>
            <td>Application</td>
            <td>Owner</td>
            <td>Date created</td>
            <td>Last login</td>
            <td></td>
          </tr>
        </thead>
          <tbody>
          {applicationsRend}
          </tbody>
        </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default ApplicationList;
