import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Home from './home/home';
import history from "./util/history";
import * as serviceWorker from './serviceWorker';
import {
  Auth0Provider
} from  './util/auth0WrapperAdmin';


import {
  Auth0Provider as Auth0ProviderUser,
} from  './util/auth0WrapperUser';

import config from './auth_config_admin.json';
import configUser from './auth_config_user.json';


const redirectUrlUser = window.location.origin+'/user';

const onRedirectCallbackAdmin = (appState) => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
}

const redirectUrlAdmin = window.location.origin+'/admins';


ReactDOM.render(
  <App />,
  document.getElementById('root')
);

/*
      <Auth0ProviderUser
                domain={configUser.domain}
                client_id={configUser.clientId}
                redirect_uri={redirectUrlUser}
                audience={configUser.audience}
                onRedirectCallback={onRedirectCallbackAdmin}
              >

        	</Auth0ProviderUser>
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
