import React, {useState, useEffect} from 'react';
import {findDOMNode} from 'react-dom';

// Needs to be global
var timedIntervalRefs = [];

function storeInterval(intervalFunctRef) {
  timedIntervalRefs.push(intervalFunctRef);
}

function clearIntervalRefs() {
  timedIntervalRefs.forEach(interval => {
    clearInterval(interval);
  }
  );

  timedIntervalRefs.splice(0, timedIntervalRefs.length);
}

function FlashMessage(props) {
  const [items, setItems] = useState([]);
  const [flashLoaded, setFlashLoaded ] = useState(false);

  const localClear =() => {
    clearIntervalRefs();
    items.splice(0, items.length);
    //setItems(items);
    props.clearMessage();
    setFlashLoaded(false);
  };

  if (props.profileLoaded && props.message != '' && props.cssName != '' && !flashLoaded) {
    setFlashLoaded(true);
    items.push(<div className={`alert alert-${props.cssName}`}>{props.message}</div>);

    const intervalFuncLocal = setInterval(localClear, 10000);
    storeInterval(intervalFuncLocal);
  }

	return (<div className="flash-message">
	  {items}
	</div>
	);
}

export default FlashMessage;
