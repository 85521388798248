import React, {useState, useEffect} from 'react';
import SideMenuButton from './sideMenuButton';
import FlashMessage from '../util/flashMessage';
import ApplicationList from './applicationList';
import ApplicationNewEdit from './applicationNewEdit';
import Results from './results';
import ProfileEdit from './profileEdit';
import history from '../util/history';
import AdminRoute from '../util/adminRoute';
import AdminService from './adminService';
import { v4 as uuidv4 } from 'uuid';

import {
  Auth0Provider,
  useAuth0
} from  '../util/auth0WrapperAdmin';

import config from '../auth_config_admin.json';

import {
  Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  Redirect
} from "react-router-dom";

function onRedirectCallbackAdmin(appState) {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const redirectUrlAdmin = window.location.origin+'/admins';

function Logout(props) {
  const { logout } = useAuth0();
  var logoutOptions = {};
  logoutOptions['returnTo'] = window.location.origin;

  return(
    <a href="#"
      onClick={(event) => {
          event.preventDefault();
          logout(logoutOptions);
        }
      }
      title= "Logout" data-toggle='tooltip' data-placement='right' data-method="delete">
      <img src={require("../assets/images/icon-logout.svg")}/>
    </a>
  );
}

function AdminsPlaceHolder(props) {
  return (
  <Auth0Provider
     domain={config.domain}
                client_id={config.clientId}
                redirect_uri={redirectUrlAdmin}
                audience={config.audience}
                onRedirectCallback={onRedirectCallbackAdmin}
              >
    <Admins match={props.match} />
  </Auth0Provider>);
}


const Admins = (props) => {
  const { match } = props;
  const {loading, user, getTokenSilently} = useAuth0();
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [region, setRegion] = useState('');
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [submitError, setError] = useState("");
  const [flashMessage, setFlashMessage] = useState("");
  const [flashCssName, setFlashCssName] = useState("");
  const [profile, setProfile] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [applications, setApplications] = useState([]);

  const [appName, setAppName] = useState("");
  const [appRegion, setAppRegion] = useState("");
  const [appFirstName, setAppFirstName] = useState("");
  const [appLastName, setAppLastName] = useState("");
  const [appEmail, setAppEmail] = useState("");
  const [appId, setAppId] = useState("");
  const [appCreatedTs, setAppCreatedTs] = useState("");
  const [appLastSignIn, setAppLastSignIn] = useState("");

  useEffect(() => {
    //let subscribed = true;
    //if (!loading && user && !profileLoading && !profileLoaded) {
    //if (subscribed) {
      //setProfileLoading(true);
      loadProfile()
        .then(localProfile => renderProfile(localProfile)  )
        .catch(error => setError(error.message) );
    //}

    return () => {
      //subscribed = false;
    };
  }, [user]);

  const loadProfile = async() => {
    let token = await getTokenSilently();
    const request = {
      source: "dataops-web",
      transactionId:  uuidv4(),
      userId: user.sub
    };

    let localProfile = await AdminService.getProfile(token, request);

    return localProfile;
  };

  const renderProfile = (localProfile) => {
      setProfile(localProfile);
      setCompanyName(localProfile.companyName);
      setIndustry(localProfile.industry);
      setRegion(localProfile.region)
      setIndustries(localProfile.industries);
      setApplications(localProfile.applications);
      setProfileLoaded(true);

      if (localProfile.companyName == null) {
        history.push("/admins/profile/new")
      }
  };

  const parentUpdate = (values) => {
    if (values['formType'] && values['formType'] == "application") {
      loadProfile().then(localProfile => renderProfile(localProfile))
                   .catch(error => setError(error.message) );
    }
    else
    {
      setCompanyName(values['companyName']);
      setIndustry(values['industry']);
      setRegion(values['region']);
    }
  }

  const adminSetFlashMessage = (message, cssName) => {
      setFlashMessage(message);
      setFlashCssName(cssName);
  };

  const clearMessage = () => {
    setFlashMessage('');
    setFlashCssName('');
  };

  const editApp = (localApp) => {
    setAppName(localApp.appName);
    setAppRegion(localApp.region);
    setAppFirstName(localApp.firstName);
    setAppLastName(localApp.lastName);
    setAppEmail(localApp.email);
    setAppId(localApp.applicationId);

    const createdDt = new Date(localApp.createdTs);
    var loggedInStr = "";

    if (localApp.userLastLoggedIn && localApp.userLastLoggedIn != '') {
      const loggedInDt = new Date(localApp.userLastLoggedIn);
      loggedInStr = loggedInDt.toString("MMM dd YYYY");
    }
    const createdDtStr = createdDt.toString("MMM dd YYYY");


    setAppCreatedTs(createdDtStr);
    setAppLastSignIn(loggedInStr);

    history.push("/admins/applications/edit");
  };

   if (loading && !user) {
     return (<div>Loading ...</div>);
   }

	return (<div>
	<div className="contentMenu" id="mobile_menu">
      <ul>
        <li>
          <a href="#">
            <i className="fa fa-user"/>
          </a>
        </li>
        <li>
          <a href="#">{require('../assets/images/icon-results.svg')}</a>
        </li>
        <li>
          <a href="#">{require("../assets/images/icon-logout.svg")}</a>
        </li>
      </ul>
    </div>
    <div className="dashboard-wrapper">
      <div className="full-row row">
        <div className="sidemenu">
          <Switch>
            <Route path={`${match.path}/applications/new`}>
              <SideMenuButton link="/admins/applications" image="back"/>
            </Route>
            <Route path={`${match.path}/applications/edit`}>
          <SideMenuButton link="/admins/applications" image="back"/>
        </Route>
        <Route>
          <SideMenuButton link="/admins/applications" image="logo"/>
        </Route>
          </Switch>
          <div className="nav-bar">
            <ul>
              {companyName && <span>
              <li>
                <Link to="/admins/applications" title= "Your Applications" data-toggle='tooltip' data-placement='right'>
                  <img src={require("../assets/images/icon-library.svg")}/>
                </Link>
              </li>
              <li>
                <Link to="/admins/results" title= "Survey Results" data-toggle='tooltip' data-placement='right'>
                  <img src={require("../assets/images/icon-results.svg")}/>
                </Link>
              </li>
              <li>
                <Link to="/admins/profile" title= "Update Profile" data-toggle='tooltip' data-placement='right'>
                  <i className="fa fa-user"/>
                </Link>
              </li>
              </span>
              }
              <li>
                <Logout/>
              </li>
            </ul>
          </div>
          <a className="mobileMenuIcon" href="#mobile_menu">
            <i className="fa fa-user"/>
          </a>
        </div>
        <div className="dashboard-container">
          <div className="dashboard-header custom-header">
            <div className="row row-full">
              <div className="large-12 medium-12 columns heading-section border-none">
                <div className="large-12 medium-12 columns">
                  <h2>{companyName} | Application Administration</h2>
                </div>
              </div>
            </div>
          </div>
          <FlashMessage profileLoaded={profileLoaded} message={flashMessage} clearMessage={clearMessage} cssName={flashCssName} />
          <Switch>
            <AdminRoute path={`${match.path}/applications/new`}>
              <ApplicationNewEdit
                setFlashMessage={adminSetFlashMessage}
                industry={industry}
                parentUpdate={parentUpdate}
                companyName={companyName}
                context="new" />
            </AdminRoute>
            <AdminRoute path={`${match.path}/applications/edit`}>
              <ApplicationNewEdit
              setFlashMessage={adminSetFlashMessage}
              appName={appName}
              region={appRegion}
              firstName={appFirstName}
              lastName={appLastName}
              region={appRegion}
              email={appEmail}
              createdTs={appCreatedTs}
              companyName={companyName}
              lastSignIn={appLastSignIn}
              profile={profile}
              context="edit" />
            </AdminRoute>
            <AdminRoute path={`${match.path}/results`}>
              <Results profile={profile} />
            </AdminRoute>
            <AdminRoute path={`${match.path}/profile/new`}>
              <ProfileEdit companyName={companyName}
                industry={industry}
                region={region}
                industries={industries}
                parentUpdate={parentUpdate}
                setFlashMessage={adminSetFlashMessage}
                context="new"/>
            </AdminRoute>
            <AdminRoute path={`${match.path}/profile`}>
              <ProfileEdit companyName={companyName}
              industry={industry}
              region={region}
              industries={industries}
              parentUpdate={parentUpdate}
              setFlashMessage={adminSetFlashMessage}
              context="edit"/>
            </AdminRoute>
            <AdminRoute path={`${match.path}/applications`}>
              <ApplicationList
                setFlashMessage={adminSetFlashMessage}
                editApplication={editApp}
                applications={applications}
              />
            </AdminRoute>
            <AdminRoute path={`${match.path}`}>
              <ApplicationList
                setFlashMessage={adminSetFlashMessage}
                applications={applications}
                editApplication={editApp}
              />
            </AdminRoute>
          </Switch>
        </div>
      </div>
    </div>
    </div>
	);
}

export default AdminsPlaceHolder;
