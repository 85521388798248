import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const getCompletedDateSeries = (applications) => {
  var dateSeries = [];
  var mapFound = [];

  dateSeries = applications
    .filter(app => {
      if (app.status === "completed" &&
      !mapFound.includes(app.updatedTs.toString())) {
        mapFound.push(app.updatedTs.toString());
        return true;
      }

      return false;
    })
    .sort((app2a, app2b) => {
      var toReturn = 0;
      if (app2a.updatedTs < app2b.updatedTs)
        toReturn = -1;
      else if (app2a.updatedTs > app2b.updatedTs)
        toReturn = 1;

      return toReturn;
    })
    .map(app3 => {
      var localeStr = new Date(app3.updatedTs).toLocaleDateString();

      var localeArr = localeStr.split("/");

      // bloody US dates ...
      return localeArr[1] + "/" + localeArr[0] + "/" + localeArr[2];
    });

  return dateSeries;
};

//@series.push({ name: application.name.to_s.titleize, data: data })

const dataSeries = (applications, dateSeries) => {
  var toReturnSeries = [];

  toReturnSeries = applications.map(app=>{
    var data = [];
    var trumpDate = new Date(app.updatedTs).toLocaleDateString();
    var trumpArr = trumpDate.split("/");
    // bloody US dates ...
    var ozzyDate = trumpArr[1] + "/" + trumpArr[0] + "/" + trumpArr[2];

    data = dateSeries.map(date => {
      return (date === ozzyDate) ? app.score : 0;
    });

    var dataPoint = {
      "name": app.appName,
      "data": data
    };

    return dataPoint;
  });

  return toReturnSeries;
};

const ResultsOverTime = (props) => {
  if (!props.profile)
    return(<div>Loading ...</div>);
  else {

    var maxScore = (props.profile.applications.length > 0 && props.profile.applications[0].maxScore) ? props.profile.applications[0].maxScore : 50;
    var ceiling = maxScore + 20;
    var dateSeries = getCompletedDateSeries(props.profile.applications);

    var options= {
         title: {
             text: ''
         },

         yAxis: {
            floor: 0,
            ceiling: ceiling,
            title: {
              text: 'Score'
            }
         },
         xAxis: {
           categories: dateSeries
         },

         legend: {
             layout: 'vertical',
             align: 'right',
             verticalAlign: 'middle'
         },

         series: dataSeries(props.profile.applications.filter(app=> app.hasOwnProperty("score")),
          dateSeries)

     };


  return(<div className="row full-row m-t30">
    <div className="medium-12 columns">
      <div className="whitebox">
        <h2>Results By Application Over Time</h2>
        <HighchartsReact
              highcharts={Highcharts}
              options={options} />
      </div>
    </div>
  </div>
    );
  }
}

export default ResultsOverTime;