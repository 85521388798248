import React from 'react';
import {
  Link
} from 'react-router-dom';

function SideMenuButton(props) {
	var imageBack = require("../assets/images/icon-back.svg");
	var imageContino =  require("../assets/images/contino-mark-black.svg");
	var imageToUse = (props.image == "back") ? imageBack : imageContino;

	return (<Link to={props.link} className="main-logo">
	  <img src={imageToUse} />
	</Link>
	);
}

export default SideMenuButton;