import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery-migrate';
import * as bxSlider from './bxSlider';
import {useForm} from 'react-hook-form';
import history from '../util/history';
import UsersService from "./usersService";
import { v4 as uuidv4 } from 'uuid';
import {
  useAuth0
} from '../util/auth0WrapperUser';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const AssessmentForm = (props) => {
  const {handleSubmit, register, errors} = useForm();
  const { loading, user, getTokenSilently } = useAuth0();
  const [isSaving, setIsSaving] = useState(false);


  //if (props.assessment.length < 1) {
  //  props.redirectBack()
  //}

  // (_*_) this is kinda everywhere, reads very wrong
  var ass = props.assessment;

  useEffect(() => {
  // Dont ask it don't work otherwise

    if(jQuery('.source-code-container .contentSlider').size() > 0 && !jQuery('.source-code-container .contentSlider').bound && submitAnswer) {
        jQuery('.source-code-container .contentSlider').bxSlider({
            infiniteLoop: false,
            hideControlOnEnd: true,
            mode: 'horizontal',
            adaptiveHeight: true,
            touchEnabled:false
        });

       jQuery('.source-code-container .contentSlider').attr("bound", true);
    }

    if (jQuery('.bx-next') && !jQuery(".bx-next").attr("bound")) {
      $(".bx-next").click(function(e){
        var formId = $(this).parents().find('li[aria-hidden="false"]').prev('li').find('form').attr('id');
        var formIdAttr = "#"+formId
        if(!$(formIdAttr).hasClass('dontSubmit')){
          //pubAnswer();
          //$(formIdAttr).submit();
        }
      });

      jQuery(".bx-next").attr("bound",  true);
    }

    if (jQuery('.bx-prev') && !jQuery(".bx-prev").attr("bound") ) {
      $(".bx-prev").click(function(e) {
        var formId = $(this).parents().find('li[aria-hidden="false"]').next('li').find('form').attr('id');
        var formIdAttr = "#"+formId
        if(!$(formIdAttr).hasClass('dontSubmit')){
          //pubAnswer();
        }
      });

      jQuery(".bx-prev").attr("bound", true);
    }
  }, [props.assessment]);

  const onSubmit = async (values) => {
    try {
      //alert(JSON.stringify(values));
      let token = await getTokenSilently();
      var request = {
        source: "dataops-web",
        transactionId: uuidv4(),
        userId: user.sub,
        userAssId: props.assessment.assId,
        status: "completed"
      };

      //submitAnswer().
      //  then(subb => {
          UsersService.updateAssessment(token, request)
            .then(result => {
              props.setFlashMessage("Assessment : ''" + props.assessment.assessment + "' has been completed.", "success");
              history.push("/users");
              props.refreshProfile();
            })
            .catch(error=>{
              props.setFlashMessage(error.message, "alert")
            });
      //  });
    } catch (error) {
      //setIsSaving(false);
      //setError(error.message);
    }
  };

  const showConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h3>Complete assessment?</h3>
            <p>You can retake this assessment later but you will need to answer all the questions again.</p>
            <br/>
            <div>
              <input type="button" value="Cancel" className="red-btn" onClick={onClose} />
              <span className="p-r5"/>
              <input type="button" value="Complete" className="blue-btn" onClick={() => {
                                                                                         onSubmit();
                                                                                         onClose();
                                                                                       }} />
            </div>
          </div>
        );
      }
    });
  }

  const submitAnswer = async (answer, notes, question) => {
    //alert("submitAnswer");
    //userService.answerQuestion();

    //alert(answer);

    if ((answer || notes) && question) {
      let token = await getTokenSilently();

      var request = {
        source: "dataops-web",
        transactionId: uuidv4(),
        userId: user.sub,
        userAssId: props.assessment.assId,
        questionNumber: question
      };

      if (answer)
        request["score"] = parseInt(answer);

      if (notes)
        request["note"] = notes;

      UsersService.answerQuestion(token, request)
        .then(result => {
            //setQuestion(null);
            //setAnswer(null);
            //setNotes(null);
        })
        .catch(error=>{
          props.setFlashMessage(error.message, "alert")
        });
      }
  };

  //pubAnswer = submitAnswer;

  const updateAnswer = (event) => {
    const val = parseInt(event.currentTarget.value) + 0;
    const questionNumber = parseInt(event.currentTarget.id) + 0;

    //setAnswer(val);
    //setQuestion(questionNumber);

    submitAnswer(val, null, questionNumber);
  };

  const updateNotes = (event) => {
    //setQuestion(parseInt(event.currentTarget.id));
    //setNotes(event.currentTarget.value);

    submitAnswer(null, event.currentTarget.value, parseInt(event.currentTarget.id));
  };

  if (!props.assessment)
    return (<div>Loading...</div>);


  var questionsRender = [];
  var count = 1;

  var sortedQuestions = ass.questions.sort((a,b) => {
    if (parseInt(a.number) < parseInt(b.number))
      return -1;
    else if (parseInt(a.number) > parseInt(b.number))
      return 1;
    else
      return 0;
  });

  sortedQuestions.forEach(
    function (question) {
      //alert(props.assessment.assessment + "-" + question.number.toString());
      var answer = props.assessment.answers
        .filter(ans=> ans.questionId === props.assessment.assessment + "-q-" + question.number.toString());

      var score = null;
      var notes = "";

      if (answer.length > 0) {
        score = answer[0].score;

        if (answer[0].notes) {
          notes = answer[0].notes;
        }
      }

      questionsRender.push(
        <li key={question.rangeKey}>
          <div className="source-code-contentbox">
            <div className="source-code-title">{`Question ${count} of ${props.assessment.questions.length}`}</div>
            <div className="source-code-content">
              <form id={count} onSubmit={handleSubmit(showConfirm)}>
                <div id="question-title">{question.question}</div>
                <input type="hidden" name="questionNo" value={question.number} />
                { question.questionType === "multiSelect" &&
                <span>
                <div className="medium-2 columns radio-border text-align-center">
                  Do Not Know
            </div>
                <div className="medium-2 columns radio-border text-align-center">
                  Strongly Disagree
            </div>
                <div className="medium-2 columns radio-border text-align-center">
                  Disagree
            </div>
                <div className="medium-2 columns radio-border text-align-center">
                  Neutral
            </div>
                <div className="medium-2 columns radio-border-full text-align-center">
                  Agree
            </div>
                <div className="medium-2 columns radio-border-end text-align-center">
                  Strongly Agree
            </div>
                <div className="medium-2 columns radio-button text-align-center">
                  <input id={question.number} type="radio" name="answer" value="0"
                    disabled={props.assessment.status === "completed" ? true : false}
                    defaultChecked={score === 0 ? true: false}
                    onChange={updateAnswer} />
                </div>
                <div className="medium-2 columns radio-button text-align-center">
                  <input id={question.number} type="radio" name="answer" value="1"
                    disabled={props.assessment.status === "completed" ? true : false}
                    defaultChecked={score === 1 ? true: false}
                    onClick={updateAnswer}  />
                </div>
                <div className="medium-2 columns radio-button text-align-center">
                  <input id={question.number} type="radio" name="answer" value="2"
                    disabled={props.assessment.status === "completed" ? true : false}
                    defaultChecked={score === 2 ? true: false}
                    onClick={updateAnswer}  />
                </div>
                <div className="medium-2 columns radio-button text-align-center">
                  <input id={question.number} type="radio" name="answer" value="3"
                    disabled={props.assessment.status === "completed" ? true : false}
                    defaultChecked={score === 3 ? true: false}
                    onClick={updateAnswer} />
                </div>
                <div className="medium-2 columns radio-button text-align-center">
                  <input id={question.number} type="radio" name="answer" value="4"
                    disabled={props.assessment.status === "completed" ? true : false}
                    defaultChecked={score === 4 ? true: false}
                    onClick={updateAnswer} />
                </div>
                <div className="medium-2 columns radio-button text-align-center">
                  <input id={question.number} type="radio" name="answer" value="5"
                    disabled={props.assessment.status === "completed"? true : false}
                    defaultChecked={score === 5 ? true: false}
                    onClick={updateAnswer} />
                </div>
                </span>
                }
                <br />
                <label>Note</label>
                <textarea disabled={props.assessment.status === "completed"? true : false} id={question.number} onBlur={updateNotes} name="note" defaultValue={notes} className="note-field" rows="3"></textarea>
                <div className="text-center">
                  {props.assessment.status != "completed" && count === props.assessment.questions.length
                    ? <input type="submit" value="Mark Complete" className="blue-btn mark-complete-button" href="#" />
                    : <div id="default-btn"></div>
                  }
                </div>

              </form>
            </div>
          </div>
        </li>
      );

      count++;
    }
  );


  return (
    <div className="dashboard-main-content">
      <div className="medium-12 columns">
        <h1 className="content-heading">{ass.title}</h1>
        <p>{ass.description}</p>
      </div>
      <div className="row row-full">
        <div className="medium-8 columns">
          <div className="source-code-container fixed-pager">
            <ul className="contentSlider">
              {questionsRender}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentForm;
