import React, {useState, useEffect} from 'react';
import AdminService from './adminService';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import history from '../util/history';
import { CSVLink } from "react-csv";

import {
  Link,
  Redirect
} from "react-router-dom";

import {
  useAuth0
} from  '../util/auth0WrapperAdmin';

import {useForm} from 'react-hook-form';

function ApplicationNewEdit (props) {
  const {loading, user, getTokenSilently} = useAuth0();
  const {handleSubmit, register, errors} = useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [submitError, setError] = useState('');
  const [csvData, setCSVData] = useState([]);

  useEffect(()=> {
    if (props.context === "edit" && props.profile) {
        var appId = user.sub + "-appname-" + props.appName;
       formatCSVData(props.profile, appId);
      }
  }, [props.profile]
  );

  const onSubmit = async (values) => {
      try {
          setIsSaving(true);
          let token = await getTokenSilently();

          values['source'] = "dataops-web";
          values['transactionId'] = uuidv4();
          values['userId'] = user.sub;
          values['industry'] = props.industry;
          values['context'] = props.context;
          values['formType'] = "application";
          values['companyName'] = props.companyName;

          if (props.context == "new")
            await AdminService.saveApplication(token, values);

          values['applicationId'] = values['userId'] + "-appname-" + values['appName'];
          values['adminName'] = user.email;

          await AdminService.sendInvite(token, values);
          setIsSaving(false);

          //props.setFlashMessage('The application has been saved and an invite has been sent', 'success');

          if (props.context == "new"){
            props.parentUpdate(values);
            history.push("/admins/applications");
            //alert('The application has been saved and an invite has been sent');
            props.setFlashMessage('The application has been saved and an invite has been sent', 'success');
          }
          else {
            //alert('The invite has been re-sent');
            props.setFlashMessage('The invite has been re-sent', 'success');
          }

      } catch (error) {
          setIsSaving(false);
          setError(error.message);
      }
  };

    var trumpDate = new Date().toLocaleDateString();;
    var trumpArr = trumpDate.split("/");
    // bloody US dates ...
    var ozzyDate = trumpArr[1] + "/" + trumpArr[0] + "/" + trumpArr[2];

    const formatCSVData = (profile, appId) => {
        var csv = [ ["Assessment", "QuestionIndex", "Question", "Score", "Notes"] ];

        var grandTotal = 0;

        profile.assessmentRefs.forEach(assess=> {
          var count=0;
          var totalScore=0;
          assess.questions.forEach(question=> {
            var answer = findUserAnswer(question.questionId, appId);

            var questionEntry = [
            assess.assessment,
            count,
            question.question,
            (answer) ? answer.score: 0,
            (answer) ? answer.notes: "",
            ];

            csv.push(questionEntry);
            totalScore =  (answer) ? totalScore + answer.score: totalScore + 0;
            count++;
          });

          csv.push(["","","Total", totalScore, ""]);
          csv.push(["","", "", "", ""]);

          grandTotal = grandTotal + totalScore;
        });

        csv.push(["","","Grand Total", grandTotal, ""]);
        csv.push(["","","Out of", profile.applications[0].maxScore, ""]);

        setCSVData(csv);
    };

    const findUserAnswer = (questionId, applicationId) => {
      if (props.profile.answers) {
        var filtered = props.profile.answers.filter(answer => answer.questionId === questionId && answer.applicationId === applicationId);

        if (filtered.length > 0)
          return filtered[0];
        else
          return null;
      } else {
        return null;
      }
    };


  if (!props.profile && !user) {
      return (
          <div>Loading ...</div>
      );
  }



/*
  if (isSaving) {
      return (
          <div>Saving profile ...</div>
      );
  }
*/
  return(
    <div className="dashboard-main-content">
      <div className="medium-12 columns">
        <h1 className="content-heading">New Application</h1>
      </div>
      <div className="row full-row m-t30">
        <div className="medium-6 columns">
          <div className="whitebox">
            <form className='new-application-form' onSubmit={handleSubmit(onSubmit)} >
              <div className="row p-b10">
                <div className="medium-12 columns">
                  <label>Application Name</label>
                  <input type="text" name="appName" autoFocus={true} placeholder="Application Name"
                    defaultValue={props.appName} ref={register({ required: true })}
                    disabled={props.context == "new" ? false : true}
                    />
                  {errors.appName &&
                    <span className="alert-alert">Please enter an application name</span>
                  }
                </div>
              </div>
              <div className="row p-b10">
                <div className="medium-12 columns">
                  <label>First Name</label>
                  <input type="text" name="firstName" autoFocus={true} placeholder="Owner First Name"
                    disabled={props.context == "new" ? false : true}
                    defaultValue={props.firstName} ref={register({ required: true })} />
                    {errors.firstName &&
                      <span className="alert-alert">Please enter a first name</span>
                    }
                </div>
              </div>
              <div className="row p-b10">
                <div className="medium-12 columns">
                  <label>Last Name</label>
                  <input type="text" name="lastName" autoFocus={true} placeholder="Owner Last Name"
                    disabled={props.context == "new" ? false : true}
                    defaultValue={props.lastName} ref={register({ required: true })} />
                    {errors.lastName &&
                      <span className="alert-alert">Please enter a last name</span>
                    }
                </div>
              </div>
              <div className="row p-b10">
                <div className="medium-12 columns">
                  <label>Region</label>
                  <input type="text" name="region" autoFocus={true} placeholder="Owner Region"
                    disabled={props.context == "new" ? false : true}
                    defaultValue={props.region} ref={register({ required: true })} />
                  {errors.region &&
                    <span className="alert-alert">Please enter a region</span>
                  }
                </div>
              </div>
              <div className="row p-b10">
                <div className="medium-12 columns">
                  <label>Email</label>
                  <input type="text" name="email" autoFocus={true} placeholder="Owner Email"
                    disabled={props.context == "new" ? false : true}
                    defaultValue={props.email} ref={register({ required: true,
                                                                               pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                    })} />
                  {errors.email &&
                    <span className="alert-alert">Please enter an email</span>
                  }
                </div>
              </div>
              <hr/>
              <div className="row p-t20">
                <div className="medium-12 columns">
                  { props.context == "new"
                    ? <span className="p-r5"><input type="submit" className="default-btn" value="Create" /></span>
                    : <span>
                        <span className="p-r5">
                          <input type="submit" value="Resend Invite" className="blue-btn"/>
                        </span>
                        <span className="p-r5">
                          <CSVLink filename={`${props.appName}-assessment-${ozzyDate}.csv`} data={csvData} target="_blank">
                            <span className="blue-btn">
                            Export Results
                            </span>
                          </CSVLink>
                        </span>
                      </span>
                  }
                  <Link to="/admins/applications" className="red-btn">Cancel</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
        { props.context === "edit" &&
          <div className="large-3 medium-6 columns left">
            <div className="whitebox">
              <div className="row p-b10">
                <div className="medium-6 columns grey">Date created</div>
                <div className="medium-6 columns">{props.createdTs}</div>
              </div>
              <div className="row">
                <div className="medium-6 columns grey">Last login</div>
                <div className="medium-6 columns">{props.lastSignIn}</div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default ApplicationNewEdit;