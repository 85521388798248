import React from 'react';

const sortedApps = (applications) => {
    return applications.sort((a, b) => {
      var toReturn = 0;
      if (a.score < b.score)
        toReturn = -1;
      else if (a.score > b.score) {
        toReturn = 1;
      }

      return toReturn;
    });
};

const ResultsTopScores = (props) => {
  if (!props.profile.applications)
    return (<div>Loading...</div>);
  else {
    var sorted = sortedApps(props.profile.applications);
    var highestApps=[];
    var lowestApps=[];

    // top and bottom 5
    var i = 0;
    var count = 0;
    for (i=0; i<sorted.length;i++) {
      if (count < 5) {
        lowestApps.push(sorted[i]);
        count++;
      }
    }

    var i = 0;
    var count = 0;

    for (i=sorted.length-1; i > -1 ; i--) {
      if (count < 5) {
        highestApps.push(sorted[i]);
        count++;
      }
    }

    return(<div className="row full-row m-t30">
      <div className="medium-6 columns">
        <div className="whitebox">
          <h2>Highest Performing Applications</h2>
          <div className="responsive-table">
            <ApplicationScores applications={highestApps} />
          </div>
        </div>
      </div>
      <div className="medium-6 columns">
        <div className="whitebox">
          <h2>Lowest Performing Applications</h2>
          <div className="responsive-table">
            <ApplicationScores applications={lowestApps} />
          </div>
        </div>
      </div>
    </div>
    );
    }
}

function ApplicationScores(props) {
  var applications = props.applications;
  var appScores = [];

  applications.forEach(
    function(app) {
      var percentage = "Not Started";
      var percentageDisplay = "Not Started";

      if (app.hasOwnProperty("score")) {
        percentage = Math.round(app.score / app.maxScore * 100).toString() + "%";
        percentageDisplay = app.score.toString() + " / "  + app.maxScore.toString();

        appScores.push(<tr>
                <td>
                  <i className="fa fa-circle blue-bullet"/>
                </td>
                <td width="150px">{app.appName}</td>
                <td>{` ${app.score} / ${app.maxScore}`}</td>
                <td>{percentage}</td>
              </tr>
              );
      }
    }
  );

  return(<table cellPadding="0" celspacing="0" style={{minWidth: 'inherit'}}>
      <thead>
        <tr>
          <td width="20px"></td>
          <td width="150px">Assessment</td>
          <td>Score</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {appScores}
      </tbody>
    </table>
   );
}

export default ResultsTopScores;