import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "./auth0WrapperAdmin";

const AdminRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: {targetUrl: window.location.pathname}
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props =>
    isAuthenticated === true ? <Component {...props} /> : <div>Unauthenticated</div>;

  return <Route path={path} render={render} {...rest} />;
};

export default AdminRoute;