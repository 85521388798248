import React, {useState, useEffect, useLayoutEffect} from 'react';

const ResultsByApplication = (props) => {

  const filter = (event) => {
    // TODO
  };

  if (!props.profile)
    return (<div>Loading ...</div>);

  return(<div className="row full-row m-t30">
  <div className="medium-12 columns">
    <div className="whitebox">
      <h2>Results By Application</h2>
      <div className="filterBar">
        <div className="row full-row m-t30">
          <div className="medium-12 columns">
              <input type="text" onChange={filter} placeholder="Search" />
          </div>
        </div>
      </div>
      <div className="responsive-table">
        <table cellPadding="0" celspacing="0">
          <thead>
            <tr>
              <td width="250px">Application</td>
              <td width="100px">Score</td>
              <td>
                <div className="proccess-no">
                  <span>0%</span>
                  <span>10%</span>
                  <span>20%</span>
                  <span>30%</span>
                  <span>40%</span>
                  <span>50%</span>
                  <span>60%</span>
                  <span>70%</span>
                  <span>80%</span>
                  <span>90%</span>
                  <span>100%</span>
                </div>
              </td>
            </tr>
          </thead>
          <ApplicationList profile={props.profile} />
        </table>
      </div>
    </div>
  </div>
  </div>
  );
}

const ApplicationList = (props) => {
  var appList = [];

  if (props.profile) {
    props.profile.applications.forEach(

    function(app) {
      var no_of_complete_line = (app.score / app.maxScore) * 100 / 10;
      var incomplete_line =  (app.score / app.maxScore) * 100 %10 * 10;

      if (app.hasOwnProperty("score")) {
        appList.push( <ApplicationRend key={app.appName} no_of_complete_line={no_of_complete_line}
         incomplete_line={incomplete_line}
         appName={app.appName}
         score={app.score}
         maxScore={app.maxScore}
         />
        );
      }
    }
    );
  }

  return (<tbody>
	  {appList}
  </tbody>
  );
};

const ApplicationRend = (props) => {
  var propsScore = "Not Started"

  if (props.hasOwnProperty("score") && props.score > -1)
    propsScore = props.score.toString() + " / "  + props.maxScore.toString();

  return(<tr>
     <td width="150px">{props.appName}</td>
     <td width="150px">{propsScore}</td>
     <td>
       <CompleteLines noLines={props.no_of_complete_line} incomplete_line={props.incomplete_line} />
     </td>
    </tr>
  );
}

const CompleteLines = (props) => {
  var completeRenderedLines = [];
  var i=0;
  for (i=0;i<props.noLines;i++) {
    completeRenderedLines.push(<ProgressLine key={i}/>);
  }

  return (<div className="proccess-bar">
    {completeRenderedLines}
    {props.incomplete_line > 0  &&
      <span><div className="proccess-bar-line" style={{width: props.incomplete_line + "%"}}></div></span>
    }
  </div>
  );
}

const ProgressLine = (props) => {
  return(<span><div className="proccess-bar-line" style={{width: "100%"}}></div></span>)
};

export default ResultsByApplication;