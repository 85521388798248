import React, { useState, useEffect } from 'react';
import history from '../util/history';
import UsersService from "./usersService";
import { v4 as uuidv4 } from 'uuid';
import {
  useAuth0
} from '../util/auth0WrapperUser';

import {
  Link
} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const AssessmentList = (props) => {
  const { loading, user, getTokenSilently } = useAuth0();

  var assessmentsRend = [];

  const editAss = async (event, found, localContext) => {
    if (event)
      event.preventDefault();

    if (!found)
      found = props.assessments.filter(ass => ass.assId === event.currentTarget.id);

    if (!localContext)
      localContext = event.currentTarget.attributes["context"].value;

    if (localContext === "notStarted") {
      updateAssessmentStatus("inProgress", found[0].assId)
        .then(result => {
          found[0].status = "inProgress";
          props.editAssessment(found[0], true);
        });
    } else if (localContext === "completed") {
      updateAssessmentStatus("inProgress", found[0].assId)
      .then(result => {
        found[0].status = "inProgress";
        found[0].answers = [];
        props.editAssessment(found[0], true);
      });
    } else {
      props.editAssessment(found[0], true);
    }
  };

  const showConfirm = (event) => {
    var found = props.assessments.filter(ass => ass.assId === event.currentTarget.id);
    var localContext = event.currentTarget.attributes["context"].value;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h3>Retake Assessment?</h3>
            <p>Retaking this assessment will reset all your previous answers.</p>
            <br/>
            <div>
              <input type="button" value="Cancel" className="red-btn" onClick={onClose} />
              <span className="p-r5"/>
              <input type="button" value="Confirm" className="blue-btn" onClick={() => {
                    editAss(null, found, localContext);
                    onClose();
                    }} />
            </div>
          </div>
        );
      }
    });
  }

  const updateAssessmentStatus = async (status, userAssId) => {
    let token = await getTokenSilently();
    var request = {
      source: "dataops-web",
      transactionId: uuidv4(),
      userId: user.sub,
      userAssId: userAssId,
      status: status
    };

    UsersService.updateAssessment(token, request)
      .then(result => {

      })
      .catch(error=>{
        props.setFlashMessage(error.message, "alert")
      });
  };

  var index = 0;

  var sliceIndex = 0;

  while (sliceIndex < props.assessments.length) {
    index = 0;
    var rowItems = [];
    var i = 0;
    for (i = sliceIndex; i < sliceIndex + 3 && i < props.assessments.length; i++) {
        var ass = props.assessments[i];
        var align_class = index > 0 ? 'align_left ' : '';
        var panelClass = "grey-panel";

        if (ass.status === "inProgress")
          panelClass = "blue-panel";
        else if (ass.status === "completed")
         panelClass = "red-panel";

        var startedDateStr = "";
        if (ass.startedDate) {
            var startedDate = new Date(ass.startedDate).toLocaleDateString();
            var trumpArr = startedDate.split("/");
            // bloody US dates ...
            startedDateStr = trumpArr[1] + "/" + trumpArr[0] + "/" + trumpArr[2];
        }

        var completedDateStr = "";
        if (ass.completedDate) {
            var completedDate = new Date(ass.completedDate).toLocaleDateString();
            var trumpArr = completedDate.split("/");
            // bloody US dates ...
            completedDateStr = trumpArr[1] + "/" + trumpArr[0] + "/" + trumpArr[2];
        }

        rowItems.push(
          <div className={`medium-4 columns ${align_class} ${panelClass}`} key={`${ass.assId}`}>
            <div className="panel-heading">
              <div className="assessment-title">{ass.assessment}</div>
            </div>
            <div className="panel-content">
              <div className="assessment-description">{ass.description}</div>
              <small>
                {ass.status === "inProgress" &&
                  <span>
                  <b>Started Date:</b>
                  <span className="p-l5">{startedDateStr}</span>
                  </span>
              }
              </small>
              <br />
              <small>
                {ass.status === "completed" &&
                  <span>
                  <b>Completed Date:</b>
                  {completedDateStr}
                  </span>
              }
              </small>
              <br />
              <p>
                {ass.status == "inProgress" &&
                  <span context="inProgress" id={`${ass.assId}`} onClick={(e)=> editAss(e)}>
                    <a className="blue-btn reduce-button-width">IN-PROGRESS</a>
                  </span>
                }
                {ass.status == "completed" &&
                  <span>
                    <span context="review" id={`${ass.assId}`} className="p-r5" onClick={(e)=> editAss(e)}>
                      <a className="default-btn blue-btn reduce-button-width">REVIEW</a>
                    </span>
                    <span context="completed" id={`${ass.assId}`} className="p-r5" onClick={(e)=> showConfirm(e)}>
                      <a className="red-btn reduce-button-width">RETAKE</a>
                    </span>
                  </span>
                }
                {ass.status == "notStarted" &&
                  <span context={`notStarted`} id={`${ass.assId}`} onClick={(e)=> editAss(e)}>
                    <a className="default-btn reduce-button-width">START</a>
                  </span>
                }
              </p>
            </div>
          </div>);
        index++;
    }
    assessmentsRend.push(
      <div className="row full-row m-t30" key={assessmentsRend.length}>
        {rowItems}
      </div>
    );
    sliceIndex = sliceIndex + 3;
  }

  return (
    <span>
    <div className="medium-12 columns">
      <h1 className="content-heading">Assessments</h1>
    </div>
    <div className="assessments-list">
      {assessmentsRend}
      <div className="title-bar">
        <div className="row" />
      </div>
    </div>
    </span>
  );
}

export default AssessmentList;
