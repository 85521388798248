const apiUrl = process.env.REACT_APP_ADMIN_API;


//'https://br3q0y8gs4.execute-api.ap-southeast-2.amazonaws.com/dev';

const AdminService = {
    editProfile: async (token, profile) => {
        const response = await fetch(`${apiUrl}/profile`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(profile),
        }).catch(function(err) {
            throw err;
        });
        if (!response.ok) {
            let error = await response.json();
            throw new Error(error.message);
        }
        const responseData = await response.json();
        return responseData;
    },
    getProfile: async (token, request) => {
        const response = await fetch(`${apiUrl}/profile/get`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request),
        }).catch(function(err) {
            throw err;
        });
        if (response.status === 404) {
          return null;
        }
        if (!response.ok) {
            let error = await response.json();
            throw new Error(error.message);
        }
        const responseData = await response.json();

        return responseData;
    },
    saveApplication: async (token, request) => {
        const response = await fetch(`${apiUrl}/applications`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request),
        }).catch(function(err) {
            throw err;
        });

        if (!response.ok) {
            let error = await response.json();
            throw new Error(error.message);
        }
        const responseData = await response.json();

        return responseData;
    },
    sendInvite: async (token, request) => {
        const response = await fetch(`${apiUrl}/invites`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request),
        }).catch(function(err) {
            throw err;
        });

        if (!response.ok) {
            let error = await response.json();
            throw new Error(error.message);
        }
        const responseData = await response.json();

        return responseData;
    }
};
Object.freeze(AdminService);
export default AdminService;
