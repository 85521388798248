import React from 'react';
import '../assets/stylesheets/admin/custom.css';
import '../assets/stylesheets/admin/grid.css';
import '../assets/stylesheets/admin/ion.rangeSlider.css';
import '../assets/stylesheets/admin/ion.rangeSlider.skinFlat.css';
import '../assets/stylesheets/admin/style.css';
import 'font-awesome/css/font-awesome.min.css';

import {
  Link
} from 'react-router-dom';

function Home(props) {

  return <div className='wrapper login-bg'>
<div>
<div className='header'>
<div className='row'>
<div className='medium-12 columns'>
<a className='logo' href='http://contino.io'>
<img src={require('../assets/images/contino-logo-white.svg')} />
</a>
</div>
</div>
</div>

<div className='main-content'>
<div className='row'>
<div className='medium-12 columns'>
<h1 className='main-heading'>DataOpsMaturity</h1>

<div className='home-slider'>
	<div className='home-slider-main'>
		<ul className='contentSlider'>
			<li>
				<p>
				<strong>Accelerate & Improve Your Software Delivery With DevOps</strong>
				</p>
				<p>DataOps Maturity is a tool to help your teams or company adopt DevOps and software delivery best practices such as Continuous Integration, Continuous Delivery, Infrastructure Automation, Agile Working and Containerisation.</p>
			</li>
			<li>
				<p>
				<strong>Self Assess & Improve</strong>
				</p>
				<p>DataOpsMaturity is a simple survey application which allows organisations and teams to self assess their current capabilities, and then improve over time using a data driven approach.</p>
			</li>
			<li>
				<p>
				<strong>How It Works</strong>
				</p>
				<p>Teams can take a series of simple surveys to self assess how they design, develop, test, deploy and run their applications.</p>
				<p>As well as generating an objective maturity score, the output of the assessment will give the team ideas for where to focus and initiatives to improve.  For instance, many teams bite off one or two improvement stories per sprint.</p>
			</li>
			<li>
				<p>
				<strong>Portfolio View</strong>
				</p>
				<p>You can also look from a top down perspective to understand areas for improvement across your organisation and application.</p>
				<p>You’ll be able to see how teams are performing against each other.  If for instance, many people are struggling with an area such as performance testing, you know to address this centrally.</p>
			</li>
			<li>
				<p>
				<strong>Who Developed This?</strong>
				</p>
				<p>The assessment has been developed by Contino, a professional services firm who have helped many organisations adopt DevOps & Continuous Delivery at scale.  We use this tool to track our clients performance improvement over time.</p>
			</li>
		</ul>
	</div>
</div>
<p className='text-center'>
	<span className="p-r5">
	<Link className="default-btn" to="/users">Login</Link>
	</span>

	<span className="p-r5">
	<Link className="default-btn" to="/admins">Login - Portfolio Admin</Link>
	</span>
</p>
</div>
</div>
</div>
</div>
<div className='footer'>
<div className='copyright-text'>
<p>Brought to you by Contino, a global consultancy that helps organizations adopt DevOps and continuous delivery in order to deliver better software more efficiently and faster</p>
</div>
</div>
</div>;
}

export default Home;