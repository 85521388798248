import React from 'react';

const ResultsAggregated = (props) => {

  if (!props.profile)
    return(<div>Loading...</div>)

  return(<div className="row full-row m-t30">
    <div className="medium-12 columns">
      <div className="whitebox">
        <h2>Aggregated Results By Category</h2>
        <div className="responsive-table">
          <table cellPadding="0" celspacing="0">
            <thead>
              <tr>
                <td width="20px"></td>
                <td width="300px">Category</td>
                <td width="150px">Assessment Score</td>
                <td>
                  <div className="proccess-no">
                    <span>0%</span>
                    <span>10%</span>
                    <span>20%</span>
                    <span>30%</span>
                    <span>40%</span>
                    <span>50%</span>
                    <span>60%</span>
                    <span>70%</span>
                    <span>80%</span>
                    <span>90%</span>
                    <span>100%</span>
                  </div>
                </td>
              </tr>
            </thead>
            <Assessment profile={props.profile} />
          </table>
        </div>
      </div>
    </div>
  </div>
    );
}

function Assessment(props) {
  var assessmentsFormat = [];

  if (props.profile.assessments)
  {
    props.profile.assessments.forEach(
      function(ass) {
        var calculate_percent = ass.averageScore / ass.maxScore * 100;
        var no_of_complete_line = calculate_percent / 10;
        var incomplete_line = (calculate_percent % 10)*10;

        assessmentsFormat.push(<tr>
          <td width="20px">
            <i className="fa fa-circle"/>
          </td>
          <td width="150px">{ass.assessment}</td>
          <td width="150px">{`${ass.averageScore} / ${ass.maxScore}`}</td>
          <td>
              <CompleteLines noLines={no_of_complete_line} incomplete_line={incomplete_line} />
           </td>
         </tr>
        );
      }
    );
  }

  return(<tbody>
    {assessmentsFormat}
  </tbody>
  );
}

function CompleteLines(props) {
  var completeLines=[];
  var i=0;
  for (i=0;i<props.noLines;i++) {
    completeLines.push(
      <span><div className="proccess-bar-line" style={{width:"100%"}} /></span>
    );
  }

  return (<div className="proccess-bar">
    {completeLines}
    {props.incomplete_line > 0  &&
      <span><div className="proccess-bar-line" style={{ width: props.incomplete_line + "%"}}/></span>
    }
  </div>
  );
}

export default ResultsAggregated;