import React from 'react';

const Survey = (props) => {
  if (!props.profile)
    return (<div>Loading...</div>);

  return(<div className="row full-row m-t30">
    <div className="medium-12 columns">
      <div className="whitebox">
        <h2>Survey Results</h2>
        <div className="responsive-table">
          <table cellPadding="0" celspacing="0">
            <thead>
              <tr>
                <td width="20px"></td>
                <td width="300px">Assessment Title</td>
                <td width="150px">Status</td>
                <td>
                  <div className="proccess-no">
                    <span>0%</span>
                    <span>10%</span>
                    <span>20%</span>
                    <span>30%</span>
                    <span>40%</span>
                    <span>50%</span>
                    <span>60%</span>
                    <span>70%</span>
                    <span>80%</span>
                    <span>90%</span>
                    <span>100%</span>
                  </div>
                </td>
              </tr>
            </thead>
            <AssessmentList profile={props.profile} />
          </table>
        </div>
      </div>
    </div>
  </div>
  );

}

function AssessmentList(props) {
  var assListRendered = [];

  props.profile.assessments.forEach(
    function(ass) {
      var calculate_percent = ass.score / ass.maxScore * 100;
      var bulletColor = "red";

      if (ass.status === "inProgress")
        bulletColor = "purple";
      else if (ass.status === "completed")
        bulletColor = "blue";


      var assessmentStatus = "Not Started";

      if (ass.status === "inProgress")
        assessmentStatus = "In-Progress";
      else if (ass.status === "completed")
        assessmentStatus = "Completed";

      assListRendered.push(<tr>
        <td width="20px">
          <div className={`dot ${bulletColor}`}></div>
        </td>
        <td width="150px">{ass.assessment}</td>
        <td width="150px">{assessmentStatus}</td>
        <td>
          <ProgressBar calculate_percent={calculate_percent} />
        </td>
      </tr>
      );
    }
  );

  return(<tbody>
    {assListRendered}
  </tbody>
  );
}

function ProgressBar(props){
  var calculate_percent = props.calculate_percent;
  var no_of_complete_line = calculate_percent / 10;
  var incomplete_line = (calculate_percent % 10)*10;

  var completedLines = [];
  var i = 0;

  for (i=0;i<no_of_complete_line;i++) {
    completedLines.push(<span>
      <div className="proccess-bar-line" style={{width: "100%"}}/>
    </span>
    );
  }


  return(<div className="proccess-bar">
    {completedLines}
    {incomplete_line > 0 &&
      <span>
        <div className="proccess-bar-line" style={{width: props.incomplete_line + "%"}} />
      </span>
    }
    </div>
  );
}

export default Survey;
