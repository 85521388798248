const apiUrl = process.env.REACT_APP_USER_API;
//const apiUrl = "https://bxfs342mx6.execute-api.ap-southeast-2.amazonaws.com/dev";

const UsersService = {
    validateInvite: async (token, request) => {
        const response = await fetch(`${apiUrl}/invite`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request),
        }).catch(function(err) {
            throw err;
        });
        if (!response.ok) {
            let error = await response.json();
            throw new Error(error.message);
        }
        const responseData = await response.json();
        return responseData;
    },
    getProfile: async (token, request) => {
        const response = await fetch(`${apiUrl}/users`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request),
        }).catch(function(err) {
            throw err;
        });
        if (response.status === 404) {
          return null;
        }
        if (!response.ok) {
            let error = await response.json();
            throw new Error(error.message);
        }
        const responseData = await response.json();

        return responseData;
    },
    answerQuestion: async (token, request) => {
        const response = await fetch(`${apiUrl}/answers`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request),
        }).catch(function(err) {
            throw err;
        });
        if (!response.ok) {
            let error = await response.json();
            throw new Error(error.message);
        }
        const responseData = await response.json();

        return responseData;
    },
    updateAssessment: async (token, request) => {
        const response = await fetch(`${apiUrl}/assessments`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request),
        }).catch(function(err) {
            throw err;
        });
        if (!response.ok) {
            let error = await response.json();
            throw new Error(error.message);
        }
        const responseData = await response.json();

        return responseData;
    },
};
Object.freeze(UsersService);
export default UsersService;
