import React, { useState, useEffect } from 'react';
import Home from './home/home';
import AdminsPlaceHolder from './admins/admins';
import UsersPlaceHolder from './users/users';
import history from './util/history';
import $ from 'jquery';
import jQuery from 'jquery-migrate';

import {
  Auth0Provider,
  useAuth0
} from  './util/auth0WrapperAdmin';
import config from './auth_config_admin.json';

import {
  Router,
  Route,
  Switch
} from 'react-router-dom';

function onRedirectCallbackAdmin(appState) {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const redirectUrlAdmin = window.location.origin+'/admins';

const App = () => {

	return (<Router history={history}>
			<Switch>
			  <Route path='/admins' component={AdminsPlaceHolder} />
        <Route path='/users' component={UsersPlaceHolder} />
        <Route path='/' component={Home} />
			</Switch>
	</Router>
  );
};

export default App;
