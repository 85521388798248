import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { useAuth0 } from "./auth0WrapperUser";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UserRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  let query = useQuery();

  const inviteId = query.get("inviteId");

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      if (inviteId) {
        await loginWithRedirect({
          appState: {
            targetUrl: window.location.pathname,
            inviteId: inviteId

          }
        });
      } else {
        await loginWithRedirect({
          appState: {
            targetUrl: window.location.pathname

          }
        });
      }
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props =>
    isAuthenticated === true ? <Component {...props} /> : <div>Unauthenticated</div>;

  return <Route path={path} render={render} {...rest} />;
};

export default UserRoute;