import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Survey from './survey';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const Results = (props) => {
  const [refreshed, setRefreshed] = useState(false);

  useEffect(() => {
      if (!refreshed) {
        props.refreshProfile();
        setRefreshed(true);
      }
    }, [props.profile]
    );

  if (!props.profile)
    return (<div>Loading...</div>);

    var donutAss={};
    donutAss['data']={
      datasets: [{
          data: [ props.profile.application.assessmentsCompleted,
            props.profile.application.assessmentsInProgress,
            props.profile.application.assessmentsNotStarted],
          backgroundColor: [ "#bf66ff", "#447dff", "#ff004a" ]
      }]
    };
    donutAss['options']={};
    donutAss['options']['cutoutPercentage']=85;

    var pctComplete = Math.round(props.profile.application.questionsAnswered / props.profile.application.totalQuestions * 100);
    var pctNotComplete = 100 - pctComplete;

    var donutAssProgress={};
        donutAssProgress['data']={
          datasets: [{
              data: [ pctComplete,
                pctNotComplete ],
              backgroundColor: [ "#3dffb9",  "#ededed" ]
          }]
        };
        donutAssProgress['options']={};
        donutAssProgress['options']['cutoutPercentage']=85;

    return(<div className="dashboard-main-content">
      <div className="medium-12 columns">
        <h1 className="content-heading">Results</h1>
      </div>
      <div className="row full-row">
        <div className="large-8 medium-12 columns">
          <div className="whitebox">
            <div className="row full-row">
              <div className="large-5 medium-12 columns text-center">
                <h5>Assessment Progress</h5>
                <div className="circles circle-height">
                  <div className="third circle second-circle">
                    <Doughnut id='report_doughnut_chart_progress' width={250} height={250}
                                    data={donutAssProgress.data} options={donutAssProgress.options}
                                    />
                    <strong>{pctComplete}
                      <span>% Complete</span>
                    </strong>

                  </div>
                </div>
                <p>{`${props.profile.application.questionsAnswered} of ${props.profile.application.totalQuestions} questions answered`}</p>
              </div>
              <div className="large-7 medium-12 columns">
                <ul className="score-details">
                  <li className="grey-dot">
                    <h3>{props.profile.assessments.length}</h3>
                    <p>Total Assessment</p>
                  </li>
                  <li className="blue-dot">
                    <h3>{props.profile.application.assessmentsInProgress}</h3>
                    <p>In-Progress</p>
                  </li>
                  <li className="purple-dot">
                    <h3>{props.profile.application.assessmentsCompleted}</h3>
                    <p>Completed &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  </li>
                  <li className="red-dot">
                    <h3>{props.profile.application.assessmentsNotStarted}</h3>
                    <p>Not Started</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="large-4 medium-12 columns">
          <div className="whitebox text-center">
            <h5>Assessment Results</h5>
            <div className="circles circle-height">
              <div className="second circle second-circle">
                <Doughnut id='report_doughnut_chart' width={250} height={250}
                  data={donutAss.data} options={donutAss.options}
                  />
                <strong>
                  { Math.round(props.profile.application.score / props.profile.application.maxScore) } %
                  <span>Total score</span>
                </strong>
              </div>
            </div>
            <p>{ `${props.profile.application.score} out of ${props.profile.application.maxScore} points`}</p>
          </div>
        </div>
      </div>
      <Survey profile={props.profile} />
      <HighChart profile={props.profile} />
    </div>
    );
};

const maxAssScore = (assessmentsSumScores) => {
  return assessmentsSumScores.reduce( (accumulate, ass) => {
    return (ass > accumulate) ? ass : accumulate;
  },
  0);
};

const getCompletedDateSeries = (assessments) => {
  var dateSeries = [];
  var mapFound = [];

  dateSeries = assessments
    .filter(ass => {
      if (ass.status === "completed" &&
      !mapFound.includes(ass.updatedTs.toString())) {
        mapFound.push(ass.updatedTs.toString());
        return true;
      }

      return false;
    })
    .sort((a, b) => {
      var toReturn = 0;
      if (a.updatedTs < b.updatedTs)
        toReturn = -1;
      else if (a.updatedTs > b.updatedTs)
        toReturn = 1;

      return toReturn;
    })
    .map(assM => {
      var localeStr = new Date(assM.updatedTs).toLocaleDateString();

      var localeArr = localeStr.split("/");

      // bloody US dates ...
      return localeArr[1] + "/" + localeArr[0] + "/" + localeArr[2];
    });

  return dateSeries;
};


const dataSeries = (assessments, dateSeries, appName) => {
  var data = [];

  dateSeries.forEach(date => {
    var sumScore = 0;
    assessments.forEach(ass => {
      var assDate = new Date(ass.updatedTs).toLocaleDateString();
      var localeArr = assDate.split("/");
      // bloody US dates ...
      var assDateStr = localeArr[1] + "/" + localeArr[0] + "/" + localeArr[2];

      if (assDateStr === date) {
        sumScore = sumScore + ass.score;
      }
    });

    data.push(sumScore);
  });

  var dataPoint = {
    "name": appName,
    "data": data
  };

  return dataPoint;
};

const HighChart = (props) => {
  var completedAss = 0;
  var completedDateSeries = getCompletedDateSeries(props.profile.assessments);
  var series = dataSeries(props.profile.assessments, completedDateSeries, props.profile.application.appName);
  var completedAssRange = maxAssScore(series.data) + 20;

  var options={
        title: {
            text: ''
        },

        yAxis: {
            floor: 0,
            ceiling: completedAssRange

        },

        xAxis: {
          categories: completedDateSeries
        },

        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },

        series: series
     };

  return(<div className="row full-row m-t30">
    <div className="medium-12 columns">
      <div className="whitebox">
        <h2>Progress</h2>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
      </div>
    </div>
  </div>
  );
};


export default Results;