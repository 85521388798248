import React, {useState, useEffect} from 'react';
import AdminService from './adminService';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import history from '../util/history';

import {
  Link,
  Redirect
} from "react-router-dom";

import {
  useAuth0
} from  '../util/auth0WrapperAdmin';

import {useForm} from 'react-hook-form';

function ProfileEdit(props) {
  const {loading, user, getTokenSilently} = useAuth0();
  const {handleSubmit, register, errors} = useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [submitError, setError] = useState('');
  const [context, setContext] = useState(props.context);
  const [companyName, setCompanyName] = useState(props.companyName);
  const [industry, setIndustry] = useState(props.industry);
  const [region, setRegion] = useState(props.region);

  const onSubmit = async (values) => {
      try {
          setIsSaving(true);
          let token = await getTokenSilently();

          values['source'] = "dataops-web";
          values['transactionId'] = uuidv4();
          values['userId'] = user.sub;

          await AdminService.editProfile(token, values);
          setIsSaving(false);
          props.parentUpdate(values);
          history.push("/admins/applications");
          history.push("/admins/profile");
          props.setFlashMessage('Profile saved', 'success');
          //alert('Profile saved');
      } catch (error) {
          setIsSaving(false);
          setError(error.message);
      }
  };

  const onChangeField = async(event) => {
    if (event.target.name == "companyName")
      setCompanyName(event.target.value);
    else if (event.target.name == "industry") {
      setIndustry(event.target.value);
    }
    else if (event.target.name == "region")
      setRegion(event.target.value);
  }

  if (loading) {
      return (
          <div>Loading ...</div>
      );
  }

  if (isSaving) {
      return (
          <div>Saving profile ...</div>
      );
  }

  return(<div className="dashboard-main-content">
    <div className="medium-12 columns">
      <h1 className="content-heading">Profile</h1>
    </div>
    <div className="row full-row m-t30">
      <div className="medium-12 columns">
        <div className="whitebox">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
            </div>
            <div className="form-field">
              <div>CompanyName</div>
              <input type="text" autoFocus={true}
              name="companyName"
              ref={register({ required: true })}
              placeholder="Company Name"
              defaultValue={props.companyName}
              />
              {errors.companyName &&
                <span className="alert-alert">Please enter a Company Name</span>
              }
            </div>
            <div className="form-field">
              <div>Industry</div>
              <IndustrySelect defValue={props.industry} register={register} industries={props.industries} />
              {errors.industry &&
                <span className="alert-alert">Please select an Industry</span>
              }
            </div>
            <div className="form-field">
              <div>Region</div>
              <input type="text" autoFocus={true}
              ref={register({ required: true })}
              placeholder="Region"
              name="region"
              defaultValue={props.region}
              />
              {errors.region &&
                <span className="alert-alert">Please enter a region</span>
              }
            </div>
            <div className="actions">
              { context == "edit" &&
              <span className="p-r5"><Link to="/admins" className="red-btn">Cancel</Link></span>
              }
              <span className="p-r5"><input type="submit" className="blue-btn"
                value={ context == "new" ? "Create": "Update"} /></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  );
}

function IndustrySelect(props) {
  const options=[];
  const selected = props.selected;
  const register = props.register;
  const onChange = props.onChange;
  const industries = props.industries;

  if (industries) {
    options.push(<option key="default" name="industry" value="default">Select Industry</option>);
    props.industries.forEach(
      function(ind) {
        var selected = (props.defValue && ind == props.defValue) ? true : false;
        options.push(<option selected={selected} key={`industry-${ind}`} name="industry" value={ind}>{ind}</option>);
      }
    );
  }

  return(<select options={options} ref={register({ required: true })} name="industry">
    {options}
    </select>
  );

}

export default ProfileEdit;