import React, { useState, useEffect } from 'react';
import FlashMessage from '../util/flashMessage';
import AssessmentList from './assessmentList';
import AssessmentForm from './assessmentForm';
import Results from './results';
import history from '../util/history';
import UserRoute from '../util/userRoute';
import { v4 as uuidv4 } from 'uuid';
import UsersService from "./usersService";
import { CSVLink } from "react-csv";


import {
  Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom";

import {
  Auth0Provider,
  useAuth0
} from '../util/auth0WrapperUser';

import configUser from '../auth_config_user.json';

function onRedirectCallback(appState) {
  if (appState.inviteId) {
    history.push(
      appState.targetUrl + "?inviteId=" + appState.inviteId
    );
  }
  else {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
};

const redirectUrl = window.location.origin + '/users';

function Logout(props) {
  const { logout } = useAuth0();
  var logoutOptions = {};
  logoutOptions['returnTo'] = window.location.origin;

  return (
    <a href="#"
      onClick={(event) => {
        event.preventDefault();
        logout(logoutOptions);
      }
      }
      title="Logout" data-toggle='tooltip' data-placement='right' data-method="delete">
      <img src={require("../assets/images/icon-logout.svg")} />
    </a>
  );
}

function UsersPlaceHolder(props) {
  return (
    <Auth0Provider
      domain={configUser.domain}
      client_id={configUser.clientId}
      redirect_uri={redirectUrl}
      audience={configUser.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <Users match={props.match} />
    </Auth0Provider>);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ValidateToken(props) {
  let query = useQuery();
  const { loading, user, getTokenSilently } = useAuth0();
  const [inviteCreated, setInviteCreated] = useState(false);

  useEffect(() => {
      validateInvite();
  }
  , [user]);

  const validateInvite = async () => {
    try {
      const inviteId = query.get("inviteId");

      let token = await getTokenSilently();
      const request = {
        source: "dataops-web",
        transactionId: uuidv4(),
        userId: user.sub,
        inviteId: inviteId
      };

      let guidReturn = await UsersService.validateInvite(token, request);
      props.inviteActionedAndRefresh();

      history.push("/users");

    } catch (error) {
      props.setFlashMessage(error.message, "alert");

      history.push("/users");
    }
  };

  return (<div />);
}

function Users(props) {
  const { match } = props;
  const { loading, user, getTokenSilently } = useAuth0();
  const [applicationName, setApplicationName] = useState('');
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [submitError, setError] = useState("");
  const [flashMessage, setFlashMessage] = useState("");
  const [flashCssName, setFlashCssName] = useState("");
  const [intervalFunc, setIntervalFunc] = useState(null);
  const [inviteActioned, setInviteActioned] = useState(false);
  const [profile, setProfile] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [assessment, setAssessment] = useState([]);
  const [csvData, setCSVData] = useState([]);

  useEffect(() => {
    //if (!loading && user && !profileLoaded) {
    // setProfileLoaded(true);
    refreshProfile();
    //}
  }
    , [user]);

  const loadProfile = async () => {
    let token = await getTokenSilently();
    const request = {
      source: "dataops-web",
      transactionId: uuidv4(),
      userId: user.sub
    };

    let localProfile = await UsersService.getProfile(token, request);


    return localProfile;
  };

  const renderProfile = (localProfile) => {
    setProfile(localProfile);
    setAssessments(localProfile.assessments);
    setProfileLoaded(true);
  };

  const inviteActionedAndRefresh = () => {
    setInviteActioned(true);

    refreshProfile();
  }

  var trumpDate = new Date().toLocaleDateString();;
  var trumpArr = trumpDate.split("/");
  // bloody US dates ...
  var ozzyDate = trumpArr[1] + "/" + trumpArr[0] + "/" + trumpArr[2];

  const refreshProfile =() => {
      loadProfile()
          .then(localProfile => {
            renderProfile(localProfile);
            formatCSVData(localProfile);
          })
          .catch(error => setError(error.message));

       //if (profile && profile.assessment)
       // formatCSVData(profile);
  };

  const formatCSVData = (localProfile) => {
      var csv = [ ["Assessment", "QuestionIndex", "Question", "Score", "Notes"] ];

      var grandTotal = 0;

      localProfile.assessments.forEach(assess=> {
        var count=0;
        var totalScore=0;
        var qsSorted = assess.questions.sort((a,b)=> {
          if (parseInt(a.number) < parseInt(b.number)) {
            return -1;
          } else if (parseInt(a.number) > parseInt(b.number)) {
            return 1;
          } else {
            return 0;
          }
        });
        qsSorted.forEach(question=> {
          var answer = findUserAnswer(assess.answers, question.rangeKey);
          //var notes = question.note;

          var questionEntry = [
          assess.assessment,
          question.number,
          question.question,
          (answer) ? parseInt(answer.score): 0,
          (answer) ? answer.notes: "",
          ];

          csv.push(questionEntry);
          totalScore = (answer)? totalScore + answer.score : totalScore + 0;

          count++;
        });

        csv.push(["","","Total", totalScore, ""]);
        csv.push(["","", "", "", ""]);
        grandTotal = grandTotal + totalScore;
      });

      csv.push(["","","Grand Total", grandTotal, ""]);
      csv.push(["","","Out of", localProfile.application.maxScore, ""]);

      setCSVData(csv);
  };

  const findUserAnswer = (answers, questionId) => {
    var filtered = answers.filter(answer => answer.questionId === questionId );

    if (filtered.length > 0)
      return filtered[0];
    else
      return null;
  };

  const storeInterval = (intervalFunct) => {
    setIntervalFunc(intervalFunct);
  };

  const userSetFlashMessage = (message, cssName) => {
    setFlashMessage(message);
    setFlashCssName(cssName);
  };

  const clearMessage = () => {
    setFlashMessage('');
    setFlashCssName('');
  };

  const editAss = (localAss, doRefreshProfile) => {
    if (doRefreshProfile) {
      loadProfile()
      .then(prof => {
        var assessment = prof.assessments.filter(ass=> ass.assId === localAss.assId)[0];
        setAssessment(assessment);
        history.push("/users/assessment");
      })
    } else {
      setAssessment(localAss);
      history.push("/users/assessment");
    }
  }

  const redirectToAssessments = () => {
    history.push("/users/assessments")
  }

  if (loading && !user) {
    return (<div>Loading ...</div>);
  }

  return (<div>
    <div className="contentMenu" id="mobile_menu">
      <ul>
        <li>
          <a href="#">
            <i className="fa fa-user" />
          </a>
        </li>
        <li>
          <a href="#">{require('../assets/images/icon-results.svg')}</a>
        </li>
        <li>
          <a href="#">{require("../assets/images/icon-logout.svg")}</a>
        </li>
      </ul>
    </div>
    <div className="dashboard-wrapper">
      <div className="full-row row">
        <div className="sidemenu">
          <Link to="/users/assessments" className="main-logo">
            <img src={require("../assets/images/contino-mark-black.svg")} />
          </Link>
          <div className="nav-bar">
            <ul>
              <li>
                <Link to="/users/assessments" title="Your Assessments" data-toggle='tooltip' data-placement='right'>
                  <img src={require("../assets/images/icon-library.svg")} />
                </Link>
              </li>
              <li>
                <Link to="/users/results" title="Survey Results" data-toggle='tooltip' data-placement='right'>
                  <img src={require("../assets/images/icon-results.svg")} />
                </Link>
              </li>
              <li>
                <a title="Export Data" data-toggle="tooltip" data-placement="right" href="#">
                  <CSVLink filename={`assessment-${ozzyDate}.csv`} data={csvData} target="_blank">
                    <i className="fa fa-file-word-o"/>
                   </CSVLink>
                </a>
              </li>
              <li>
                <Logout />
              </li>
            </ul>
          </div>
          <a className="mobileMenuIcon" href="#mobile_menu">
            <i className="fa fa-user" />
          </a>
        </div>
        <div className="dashboard-container">
          <div className="dashboard-header custom-header">
            <div className="row row-full">
              <div className="large-12 medium-12 columns heading-section border-none">
                <div className="large-12 medium-12 columns">
                  <h2>
                    <span className="p-r5">Application</span>{
                    profile &&
                    <div className="heading-section">{profile && profile.application.appName}</div>
                    }
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <FlashMessage profileLoaded={profileLoaded} message={flashMessage} clearMessage={clearMessage} cssName={flashCssName} />
          <div class="dashboard-main-content">
          <Switch>
            <UserRoute path={`${match.path}/assessments`}>
              <AssessmentList
                setFlashMessage={userSetFlashMessage}
                assessments={assessments}
                editAssessment={editAss}
                refreshProfile={refreshProfile}
              />
            </UserRoute>
            <UserRoute path={`${match.path}/assessment`}>
              <AssessmentForm 
                setFlashMessage={userSetFlashMessage}
                context="edit" 
                assessment={assessment}
                redirectBack={redirectToAssessments}
                refreshProfile={refreshProfile}
                />
            </UserRoute>
            <UserRoute path={`${match.path}/results`}>
              <Results profile={profile} refreshProfile={refreshProfile} />
            </UserRoute>
            <UserRoute path={`${match.path}/invite`}>
              <ValidateToken
                inviteActioned={inviteActioned}
                inviteActionedAndRefresh={inviteActionedAndRefresh}
                loading={loading}
                profileLoaded={profileLoaded}
                setFlashMessage={userSetFlashMessage}
              />
            </UserRoute>
            <UserRoute path={`${match.path}`}>
              <AssessmentList
                setFlashMessage={userSetFlashMessage}
                assessments={assessments}
                editAssessment={editAss}
                refreshProfile={refreshProfile}
              />
            </UserRoute>
          </Switch>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}


export default UsersPlaceHolder;
