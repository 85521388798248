import React from 'react';

import ResultsByApplication from './resultsByApplication';
import ResultsAggregated from './resultsAggregated';
import ResultsOverTime from './resultsOverTime';
import ResultsTopScores from './resultsTopScores';

const Results = (props) => {

  if (!props.profile)
    return (<div>Loading ...</div>);

  return (<div className="dashboard-main-content">
    <div className="medium-12 columns">
		<h1 className="content-heading">Results Summary</h1>
		</div>
	  <ResultsByApplication profile={props.profile} />
	  <ResultsOverTime profile={props.profile} />
	  <ResultsAggregated profile={props.profile} />
	  <ResultsTopScores profile={props.profile}/>
  </div>
  );

};


export default Results;